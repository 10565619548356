<template>
  <!-- 地区单选 -->
  <div>
    <!-- <span class="m-r-8">所在地区</span> -->
    <el-cascader :placeholder="placeholder" :props="ownRegionProps" v-model="ownRegion" collapse-tags :options="ownRegionList" clearable ref="cascader"
                 size="medium" class="diquClass">
    </el-cascader>
  </div>
</template>

<script>
import { provinceList, cityList, districtList, getAreaTree } from "../api/login/index";
export default {
  data() {
    return {
      ownRegionList: [],
      ownRegion: [],
      ownRegionProps: {
        // multiple: true,
        checkStrictly: true,
        expandTrigger: 'hover',
        // label: 'name',
        // value: 'id',
        // children: 'subset'
        lazy: true,
        lazyLoad: this.ownRegionLoadNode,
      }, // 地区选择 
      placeholder: '请选择'
    }
  },
  mounted() {
    this.provinceList();
  },
  methods: {
    //初始化地区列表
    async provinceList() {
      let res = await provinceList({});
      // 所属区域数据
      if (res && res.data && res.data.data && res.data.data.length > 0) {
        let ownRegionList = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
          };
        });

        this.ownRegionList = ownRegionList.filter(v => v.label !== '康奈网络');
      }
    },
    //机构所属区域
    async ownRegionLoadNode(node, resolve) {
      let data = {
        parentCode: node.data ? node.data.code : null,
      };
      let dat = [];
      if (node.level == 1) {
        let res = await cityList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
          };
        });
      }
      if (node.level == 2) {
        let res = await districtList(data);
        dat = res.data.data.map((x) => {
          return {
            label: x.name,
            value: x.id,
            code: x.code,
            // lazy: -1,
          };
        });
      }
      resolve(dat);
    },
    //  地区选择
    backOwnRegion(inx) {
      console.log(this.ownRegion)
      // let arr = []
      // this.ownRegion.forEach((v) => {
      //   if (v[inx]) arr.push(v[inx])
      // })
      // return [...new Set(arr)]
      return this.ownRegion
    }
  }
}
</script>
<style lang="scss" scoped>
.diquClass {
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #333333;
  }
}
</style>