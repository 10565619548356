<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="@/assets/img/wang/back.png" @click="back" alt="" />
        <span>编辑APP信息</span>
      </div>
      <div class="head-right">
        <div class="cancle" @click="back">取消</div>
        <div class="enter" @click="determine">确定</div>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="app" v-loading="editLoading">
        <div class="title">
          <img src="@/assets/img/wang/tip.png" alt="" />
          <span>APP信息</span>
        </div>
        <el-row :gutter="20">
          <el-form :model="appForm" :rules="rules" @submit.native.prevent ref="appForm" label-width="200px">
            <el-col :span="11">
              <el-form-item label="应用名称" prop="applyName">
                <el-input placeholder="请输入内容" class="input" clearable v-model.trim="appForm.applyName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="应用类别" prop="applicationCategory">
                <el-input placeholder="请输入内容" class="input" clearable v-model.trim="appForm.applicationCategory"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="下载量">
                <el-input placeholder="请输入内容" class="input" clearable v-model="appForm.downloadNum"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="最后更新时间">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="datetime" class="select" v-model="appForm.latestTime" clearable placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="11">
              <el-form-item label="开发者名称">
                <el-input placeholder="请输入内容" class="input" clearable v-model="appForm.developerName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="法人代表">
                <el-input placeholder="请输入内容" class="input" clearable v-model="appForm.legalRepresentative"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="联系信息">
                <el-input placeholder="请输入内容" class="input" clearable v-model="appForm.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="注册地址">
                <el-input placeholder="请输入内容" class="input" clearable v-model="appForm.registeredAddress"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="运营者名称">
                <el-input placeholder="请输入内容" class="input" clearable v-model="appForm.operator"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="所属区域">
                <el-input placeholder="请输入内容" class="input" clearable v-model="appForm.areaName"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import AreaTree from "@/components/areaTree.vue";
import { appUpdate } from "@/api/appAccount/index";
export default {
  components: {
    Breadcrumb,
    AreaTree
  },
  data() {
    return {
      routerList: ["APP台账", "编辑APP信息"],
      infoObj: {},
      rules: {
        // accountName: [
        //   { required: true, message: "请输入网站名称", trigger: "blur" },
        // ], //网站名称
        // domainName: [
        //   { required: true, message: "请输入网站域名", trigger: "blur" },
        // ], //网站域名
      },
      appForm: {
        //app信息表单
        applyName: '',// 应用名称
        applicationCategory: '',// 应用类别
        downloadNum: '',// 下载量
        latestTime: '',// 最后更新时间
        developerName: '',// 开发者名称
        legalRepresentative: '',// 法人代表
        phone: '',// 联系信息
        registeredAddress: '',// 注册地址
        operator: '',// 运营者名称
        areaName: '',// 所属区域
        id: '',//编辑要用
      },
      editLoading: false,//编辑loading
      params:{}
    }
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    console.log(params);
    this.params = params
    // 回显
    let { appForm } = this
    appForm.applyName = params.applyName
    appForm.applicationCategory = params.applicationCategory
    appForm.downloadNum = params.downloadNum
    appForm.latestTime = params.latestTime
    appForm.developerName = params.developerName
    appForm.legalRepresentative = params.legalRepresentative
    appForm.phone = params.phone
    appForm.registeredAddress = params.registeredAddress
    appForm.operator = params.operator
    appForm.areaName = params.areaName
    appForm.id = params.id
  },
  mounted() {
  },
  methods: {

    back() {
      this.$main.routeResolve('/appDetails', this.params)
    },
    async determine() {
      this.editLoading = true
      let data = this.appForm
      console.log(this.appForm);
      // return
      let res = await appUpdate(data)
      if (res.data.code == 200) {
        this.editLoading = false
        this.$message.success('修改成功')
        this.$router.push('/appAccount')
      } else {
        this.editLoading = false
        this.$message.error('修改失败,请稍后再试')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.overall-page {
  width: 100%;
  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;
    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;
      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
    .head-right {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      width: calc(50% - 228px);
      align-items: center;
      .cancle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 36px;
        background: #fff1e8;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ed731f;
        margin-right: 16px;
        cursor: pointer;
      }
      .enter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 36px;
        background: #f58030;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        margin-right: 24px;
      }
    }
  }
  .page {
    padding-top: 72px;
    .app {
      display: flex;
      flex-direction: column;
      width: 100%;
      // min-height: 574px;
      height: auto;
      padding-bottom: 32px;
      background: #ffffff;
      border-radius: 8px;
      margin: 16px 0;
      .title {
        display: flex;
        align-items: center;
        height: 22px;
        margin: 25px 0px 31px 19px;
        img {
          height: 22px;
          width: 22px;
          margin-right: 3px;
        }
        span {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
        }
      }
      .input {
        //输入框
        width: 660px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 660px;
        }
      }
      .time-select {
        width: 660px;
        height: 36px;
        margin: 0;
        ::v-deep .el-input__inner {
          height: 36px;
        }
      }
      //表单
      ::v-deep .el-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        .el-form-item__label {
          display: flex;
          align-items: center;
          height: 48px;
          padding-left: 33px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .el-form-item__content {
          margin-left: 33px !important;
        }
      }
      ::v-deep .el-col.el-col-24 {
        height: 148px;
      }
      .select {
        //下拉选择
        width: 660px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 660px;
          height: 36px;
        }
      }
    }
  }
}
</style>